import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { LinkExternalIcon } from '@primer/octicons-react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ImageBox = makeShortcode("ImageBox");
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`What are Figma libraries?`}</h2>
    <p>{`Figma libraries contain UI components and design tokens (`}<inlineCode parentName="p">{`variables`}</inlineCode>{` and `}<inlineCode parentName="p">{`styles`}</inlineCode>{`). At GitHub we have one product design system library called `}<em parentName="p">{`Primer Web`}</em>{` that our teams at GitHub use to design GitHub. The components contained within Primer match what is available for developers in Primer React Components, Primer ViewComponents, and Primer CSS.`}</p>
    <h2>{`Installation`}</h2>
    <p>{`To use a library in Figma enable (install) it from the `}<strong parentName="p">{`assets`}</strong>{` tab (`}<inlineCode parentName="p">{`option`}</inlineCode>{` + `}<inlineCode parentName="p">{`2`}</inlineCode>{`).
You can also directly open the `}<em parentName="p">{`team library`}</em>{` view via the command palette or with the shortcut `}<inlineCode parentName="p">{`option`}</inlineCode>{` + `}<inlineCode parentName="p">{`cmd`}</inlineCode>{` + `}<inlineCode parentName="p">{`o`}</inlineCode>{`.`}</p>
    <ImageBox caption="Navigate to the assets tab in the left sidebar and press the book icon to open the libraries overview." mdxType="ImageBox">
  <img width="490" alt="Screenshot showing the assets tab in figma" src="https://user-images.githubusercontent.com/813754/221525999-d0278cec-d79c-40fc-8150-532f97549dc8.png" />
    </ImageBox>
    <ImageBox caption="Enable Primer Web and all other libraries you need in your file." mdxType="ImageBox">
  <img width="508" alt="Screenshot showing the library overview in figma" src="https://github.com/primer/react/assets/813754/675eb97e-479d-47db-bd29-f10c136f586a" />
    </ImageBox>
    <h3>{`Styles and variables`}</h3>
    <p>{`Styles and variables are two ways to use `}<a parentName="p" {...{
        "href": "https://github.com/primer/primitives"
      }}>{`Primer Primitives`}</a>{` (design tokens) in Figma.
Our goal is to move everything over to variables, but at the moment only color and size tokens are supported. This is why we still provide text and shadow tokens using styles.`}</p>
    <h3>{`Understanding styles`}</h3>
    <p>{`Figma libraries like `}<em parentName="p">{`Primer Web`}</em>{` provide styles for you to use. In contrast to local styles, styles from a library don't show up in the sidebar. However, once you open a selection to choose a `}<inlineCode parentName="p">{`text style`}</inlineCode>{` or `}<inlineCode parentName="p">{`shadow style`}</inlineCode>{` you will see the styles from team libraries as well.`}</p>
    <p>{`Available styles:`}</p>
    <ul>
      <li parentName="ul">{`text styles`}</li>
      <li parentName="ul">{`shadow styles`}</li>
    </ul>
    <p>{`To quickly find a style you can use the search box. E.g. search for `}<inlineCode parentName="p">{`body`}</inlineCode>{` to bring up all body text styles as well as related ones.`}</p>
    <ImageBox caption="Text style selector with search for 'body'" mdxType="ImageBox">
  <img width="261" alt="Screenshot showing the style selector in figma" src="https://github.com/primer/react/assets/813754/4537b229-a663-499d-b0f0-d6be0d9fdadb" />
    </ImageBox>
    <h3>{`Understanding variables (primitives)`}</h3>
    <p>{`Our Figma libraries use `}<inlineCode parentName="p">{`variables`}</inlineCode>{` to represent design tokens. Variables from a library don't show up in the sidebar.
However once you open a selection to choose a color, or size, etc. you will see the variables from team library as well.`}</p>
    <p>{`To use the `}<inlineCode parentName="p">{`variables`}</inlineCode>{` you need to enable the `}<inlineCode parentName="p">{`Primer Web`}</inlineCode>{` library in your file.`}</p>
    <p>{`Available variables:`}</p>
    <ul>
      <li parentName="ul">{`color variables`}</li>
      <li parentName="ul">{`size variables`}</li>
    </ul>
    <p>{`To quickly find a `}<inlineCode parentName="p">{`variable`}</inlineCode>{` you can use the search box. E.g. use `}<inlineCode parentName="p">{`accent`}</inlineCode>{` to bring up all accent colors or `}<inlineCode parentName="p">{`fgColor`}</inlineCode>{` to find all text related colors. `}</p>
    <ImageBox caption="Color variables panel and panel with a search for 'fgCo'" mdxType="ImageBox">
  <img width="509" alt="Screenshot showing the color variable panel in figma" src="https://github.com/primer/design/assets/813754/34835137-cc62-45ce-9abf-3573089eb278" />
    </ImageBox>
    <h4>{`Scope`}</h4>
    <p>{`Variables in Figma are `}<em parentName="p">{`"scoped"`}</em>{`, meaning they can only be used in specific situations. Currently Figma offers the following scopes for color variables: `}<inlineCode parentName="p">{`Frame fill`}</inlineCode>{`, `}<inlineCode parentName="p">{`Shape fill`}</inlineCode>{`, `}<inlineCode parentName="p">{`Text fill`}</inlineCode>{` , `}<inlineCode parentName="p">{`Stroke`}</inlineCode>{`.`}</p>
    <p>{`For you this means that if you want to create a border using a `}<inlineCode parentName="p">{`borderColor`}</inlineCode>{` you need to use a `}<inlineCode parentName="p">{`stroke`}</inlineCode>{` (path tool) or a `}<inlineCode parentName="p">{`border`}</inlineCode>{` around a `}<inlineCode parentName="p">{`frame`}</inlineCode>{` or `}<inlineCode parentName="p">{`shape`}</inlineCode>{`. Similarly `}<inlineCode parentName="p">{`fgColors`}</inlineCode>{` can not be used for borders and `}<inlineCode parentName="p">{`bgColors`}</inlineCode>{` can only be used for `}<inlineCode parentName="p">{`frame fills`}</inlineCode>{` and `}<inlineCode parentName="p">{`shape fills`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` There are two exceptions to this rule.`}</p>
    <ol>
      <li parentName="ol">{`Some `}<inlineCode parentName="li">{`bgColors`}</inlineCode>{` are available for `}<inlineCode parentName="li">{`borders`}</inlineCode>{` and `}<inlineCode parentName="li">{`strokes`}</inlineCode>{` because we use them when placing things like notification dots on top of elements like an avatar.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`fgColors`}</inlineCode>{` are also available for `}<inlineCode parentName="li">{`shape fill`}</inlineCode>{`. This is nessary because icons are shapes, but use `}<inlineCode parentName="li">{`fgColors`}</inlineCode></li>
    </ol>
    <ImageBox caption="Color variable panel with scope 'backgroundColor' and 'borderColor'" mdxType="ImageBox">
  <img width="700" alt="Screenshot showing the color variable panel with specific scopes in figma" src="https://github.com/primer/design/assets/813754/8edb4f44-38c5-4ee1-a520-356c35f4b4ff" />
    </ImageBox>
    <a href="https://help.figma.com/hc/en-us/sections/14506605769879-Variables">Learn more about Figma variables <LinkExternalIcon mdxType="LinkExternalIcon" /></a>
    <h3>{`Variables vs. styles`}</h3>
    <p>{`Variables and styles are fairly similar, but there are some key differences. You can identify a color variables by the squared color representation and a style by the round one.`}</p>
    <p><strong parentName="p">{`Styles`}</strong>{` can be used for color, grids and shadows. The can have multiple colors, pictures or gradients combined in one style and they can be replaced using the style swap utility. However they can not be nested.`}</p>
    <p><strong parentName="p">{`Variables`}</strong>{` are more similar to design tokens in that they can only have a single raw value, either a color, string or number. However they can reference another valid variable e.g. the variable `}<inlineCode parentName="p">{`fgColor/danger`}</inlineCode>{` can reference `}<inlineCode parentName="p">{`base/color/red/4`}</inlineCode>{`. This makes variables a better choice to represent design tokens.`}</p>
    <h2>{`Understanding components`}</h2>
    <p>{`At GitHub, we have created a `}<a parentName="p" {...{
        "href": "https://github.com/primer/figma/blob/main/docs/authoring-components.md"
      }}>{`set of guidelines`}</a>{` that contributors and maintainers can reference when creating and updating components.`}</p>
    <p>{`Components in our libraries have been built to be easy to understand for consumers. When possible try to keep components small and simple. Avoid advanced methods/tricks if possible. `}</p>
    <h3>{`Variants and component properties`}</h3>
    <p>{`To make components dynamic, we favor `}<a parentName="p" {...{
        "href": "https://help.figma.com/hc/en-us/articles/5579474826519-Explore-component-properties"
      }}>{`component properties `}<LinkExternalIcon mdxType="LinkExternalIcon" /></a>{` over nesting, so users don't have to override parts of a component manually. `}</p>
    <p>{`Whenever possible use components as they are without `}<em parentName="p">{`detaching`}</em>{`.`}</p>
    <a href="https://help.figma.com/hc/en-us/articles/360038662654-Guide-to-components-in-Figma">Learn more about Figma components <LinkExternalIcon mdxType="LinkExternalIcon" /></a>
    <h2>{`Using variable themes`}</h2>
    <p>{`Primer Web provides `}<em parentName="p">{`light mode`}</em>{` and `}<em parentName="p">{`dark mode`}</em>{` using figma `}<inlineCode parentName="p">{`variables`}</inlineCode>{`. This means you don't need a plugin to change between modes. Simply select any `}<inlineCode parentName="p">{`frame`}</inlineCode>{` that uses `}<inlineCode parentName="p">{`variables`}</inlineCode>{` or components with `}<inlineCode parentName="p">{`variables`}</inlineCode>{`. Select the mode from the dropdown `}<svg className="svg" xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12"><path fill="#000" fillOpacity="1" fillRule="evenodd" d="m5 1.381-4 2.31v4.618l4 2.31 4-2.31V3.691L5 1.38zm5 1.732L5 .227 0 3.113v5.774l5 2.887 5-2.887V3.113zm3 5.196-5 2.887 1 .578 5-2.887V3.113L9 .227 8 .804l5 2.887v4.618zM6 6c0 .552-.448 1-1 1-.552 0-1-.448-1-1 0-.552.448-1 1-1 .552 0 1 .448 1 1z"></path></svg>{` in the `}<em parentName="p">{`layer`}</em>{` section in the right sidebar`}</p>
    <ImageBox caption="Switching the variable mode changes all nested items" mdxType="ImageBox">
  <CustomVideoPlayer width="804" loop autoPlay src="https://github.com/primer/design/assets/813754/a58ce41e-e4de-4267-9219-4d180350a8f0" mdxType="CustomVideoPlayer" />
    </ImageBox>
    <h3>{`Video introduction to primer web variables and modes / themes (GitHub staff only)`}</h3>
    <a href="https://github.rewatch.com/video/qvryg0cscoowmpyx-figma-primer-web-update-to-figma-variables"><img src="https://thumbnails.rewatch.com/thumbs/qvryg0cscoowmpyx?fm=jpg&p=true&q=100" /></a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      